#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17.5px;
  background: #FFFFFF;

  .img1 {
    margin-right: 32px;
    cursor: pointer;
  }
}
