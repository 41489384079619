body {
  background: #EDEDED !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Plus Jakarta Display";
  }

  * {
    font-family: "Plus Jakarta Text";
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bold-700{
  font-weight: 700;
}

.filter-btn{
  all: unset;
  cursor: pointer;
  background: #EFF1FB;
  border: 1px solid #C8CFF2;
  border-radius: 2px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
}