@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;400;500;600;700&display=swap');@import url('https://fonts.cdnfonts.com/css/hk-groteks');
@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Anek Telugu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}

.ant-tabs-tab { 
  font-style: normal;
  font-weight: 400;
  font-size: 20px; 
  color: #858585 !important;

} 

.ant-tabs-tab:hover {
    color: #242424 !important;
}

.ant-tabs-tab-active {
  color: #242424 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px; 
  
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #363636 !important;  
}

.ant-tabs-ink-bar { 
  background: #242424 !important;
}
