$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  ) !default;
#main {
  // margin-left: $sidebar-width; 
  // height: 100vh;
  // overflow: auto;
  // padding: 0 !important;
  // background-color: #F6F5FA;
  // transition: $all-transition;
  // @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
  //   // Sidebar default state (on mobile)
  //   margin-left: 0;
  // }
    margin-left: 234px;
    transition: all 0.5s ease; 
    background: white; 

    &.toggled {
        margin-left: 0;
    }

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        // Sidebar default state (on mobile)
        margin-left: 0;
    }
}
