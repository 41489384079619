@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css');
@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-text.min.css');

@import './variables';
@import './mixins';
@import "../../components/defaults/Navbar/navbar.scss";
@import "../../components/defaults/Sidebar/sidebar.scss";
@import './components/main.scss';

@import 'bootstrap/scss/bootstrap';
