$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;

#sidebar {
  // overflow-y: scroll !important;
  width: 234px;
  max-height: 100vh;
  background-color: #181818;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 0;
  bottom: 0;
  z-index: 1;
  // overflow-y: scroll;
  transition: all 0.5s ease;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &.toggled {
    margin-left: -234px;
  }
  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    // Sidebar default state (on mobile)
    margin-left: -234px;

    // Sidebar toggled state (on mobile)
    &.toggled {
      margin-left: 0;
    }
  }
  .logo {
    padding: 24px;
    background-color: #fff;
  }
  .default {
    margin-top: 32px;
    div {
      margin-bottom: 24px;
      padding: 12px 32px;
      display: flex;
      align-items: center;
      color: #aeaeae;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      position: relative;
      display: block;
      z-index: 1;
      transition: 0.35s ease color;

      &:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        border-left: solid 5px #fff;
        background: #df9e30;
        transition: 0.35s ease left;
      }
      &.is-current,
      &:hover {
        &:before {
          left: 0;
        }
      }
      img {
        margin-right: 16px;
      }
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
    }
    div:hover {
      span {
        color: #181818;
      }
    }
  }
}

/* width */
#sidebar::-webkit-scrollbar {
  // width: 5px !important;
}

/* Track */
#sidebar::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px white !important;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
  // background: #fff !important;
}
