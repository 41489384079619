$white: #fff;
$black: #000;
$primary: #DF9E30;
$primal: #9F96CD;
$primaryLight: #7467b6;
$deepPurple: #110c35;
$deeperPurple: #090525;
$sidebarBg: #110c35;
$lightPurple: #14213d;
$creamWhite: #f6f5fa;
$accent: #ecc708;
$textGrey: #434d64;
$textGrey2: #727a8b;
$grey700: #344054;
$bgGrey: #f6f5fa;
$borderGrey: #a1a6b1;
$borderGreyLight: #f3f4f5;
$red: #f04c4c;
$errorPink: #f69494;
$btnBorder: #d0d3d8;
$success: #03d858;
$accent2: #f9eeb5;
$bbb: #f3f4f5;

$sidebar-width: 250px;
$all-transition: all 0.5s ease;

$primary-gradient: linear-gradient(49.06deg, #261d57 19.9%, #6554c0 88.46%);
// CUSTOM THEME
$theme-colors: (
  'primary': $primary,
  'creamWhite': $creamWhite,
  'deepPurple': $deepPurple,
  'lightPurple': $lightPurple,
  'accent': $accent,
  'greyBg': $bgGrey,
  'textGrey': $textGrey,
  'textGrey2': $textGrey2,
  'borderGreyLight': $borderGreyLight,
  'red': $red,
  'accent2': $accent2,
  'textGrey3': $borderGrey,
  'deepestPurple': $sidebarBg,
  'deepGrey': $btnBorder,
  'grey700': $grey700,
  'bbb': $bbb,
  'primal': $primal,
  'primaryLight': $primaryLight,
);

html {
  overflow: scroll;
  overflow-x: hidden;
}
// ::-webkit-scrollbar {
// width: 10px;  /* Remove scrollbar space */
// min-height: 90vh;
// background: transparent;  /* Optional: just make scrollbar invisible */
// border-radius: 59%;
// }
/* Optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
// background: $deepPurple;
// }
